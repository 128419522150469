exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mishna-study-index-jsx": () => import("./../../../src/pages/mishna-study/index.jsx" /* webpackChunkName: "component---src-pages-mishna-study-index-jsx" */),
  "component---src-pages-mishna-study-parts-jsx": () => import("./../../../src/pages/mishna-study/parts.jsx" /* webpackChunkName: "component---src-pages-mishna-study-parts-jsx" */),
  "component---src-pages-talmud-study-index-jsx": () => import("./../../../src/pages/talmud-study/index.jsx" /* webpackChunkName: "component---src-pages-talmud-study-index-jsx" */),
  "component---src-pages-talmud-study-parts-jsx": () => import("./../../../src/pages/talmud-study/parts.jsx" /* webpackChunkName: "component---src-pages-talmud-study-parts-jsx" */),
  "component---src-pages-yerushalmi-jsx": () => import("./../../../src/pages/yerushalmi.jsx" /* webpackChunkName: "component---src-pages-yerushalmi-jsx" */),
  "component---src-templates-mishna-study-masechtot-masechtot-jsx": () => import("./../../../src/templates/MishnaStudy/Masechtot/Masechtot.jsx" /* webpackChunkName: "component---src-templates-mishna-study-masechtot-masechtot-jsx" */),
  "component---src-templates-mishna-study-perakim-perakim-jsx": () => import("./../../../src/templates/MishnaStudy/Perakim/Perakim.jsx" /* webpackChunkName: "component---src-templates-mishna-study-perakim-perakim-jsx" */),
  "component---src-templates-parasha-study-perakim-perakim-jsx": () => import("./../../../src/templates/ParashaStudy/Perakim/Perakim.jsx" /* webpackChunkName: "component---src-templates-parasha-study-perakim-perakim-jsx" */),
  "component---src-templates-parasha-study-plus-sefarim-sefarim-jsx": () => import("./../../../src/templates/ParashaStudyPlus/Sefarim/Sefarim.jsx" /* webpackChunkName: "component---src-templates-parasha-study-plus-sefarim-sefarim-jsx" */),
  "component---src-templates-parasha-study-sefarim-sefarim-jsx": () => import("./../../../src/templates/ParashaStudy/Sefarim/Sefarim.jsx" /* webpackChunkName: "component---src-templates-parasha-study-sefarim-sefarim-jsx" */),
  "component---src-templates-talmud-study-dapim-dapim-jsx": () => import("./../../../src/templates/TalmudStudy/Dapim/Dapim.jsx" /* webpackChunkName: "component---src-templates-talmud-study-dapim-dapim-jsx" */),
  "component---src-templates-talmud-study-masechtot-masechtot-jsx": () => import("./../../../src/templates/TalmudStudy/Masechtot/Masechtot.jsx" /* webpackChunkName: "component---src-templates-talmud-study-masechtot-masechtot-jsx" */),
  "component---src-templates-tanach-study-perakim-perakim-jsx": () => import("./../../../src/templates/TanachStudy/Perakim/Perakim.jsx" /* webpackChunkName: "component---src-templates-tanach-study-perakim-perakim-jsx" */),
  "component---src-templates-tanach-study-sefarim-sefarim-jsx": () => import("./../../../src/templates/TanachStudy/Sefarim/Sefarim.jsx" /* webpackChunkName: "component---src-templates-tanach-study-sefarim-sefarim-jsx" */),
  "component---src-templates-teachers-all-teachers-jsx": () => import("./../../../src/templates/Teachers/AllTeachers.jsx" /* webpackChunkName: "component---src-templates-teachers-all-teachers-jsx" */),
  "component---src-templates-teachers-teacher-jsx": () => import("./../../../src/templates/Teachers/Teacher.jsx" /* webpackChunkName: "component---src-templates-teachers-teacher-jsx" */)
}

